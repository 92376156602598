<template>
  <div class="squaredThree">
    <input type="checkbox" value="None" name="check" v-model="checkbox" />
    <label
      @click="
        () => {
          checkbox = !checkbox;
        }
      "
      class="flex items-center justify-center"
    >
      <svg
        v-if="checkbox"
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 text-green-700"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clip-rule="evenodd"
        />
      </svg>
    </label>
    <span
      class="input-checkbox-placeholder text-md"
      @click="
        () => {
          checkbox = !checkbox;
        }
      "
      v-if="placeholder"
      >{{ placeholder }}

      <a
        v-if="link"
        target="_blank"
        class="mx-1 underline text-green-600 hover:text-green-800 visited:text-green-700 cursor-pointer"
        :href="url"
        >{{ link }}</a
      >
      {{ secondplaceholder }}</span
    >
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: false,
    },
    secondplaceholder: {
      type: String,
      required: false,
    },
    checked: {
      type: Boolean,
      required: false,
    },
    link: {
      type: String,
      required: false,
    },
    url: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      checkbox: this.checked ? true : false,
      date: Date.now(),
    };
  },
  watch: {
    checkbox() {
      this.$emit('checkbox', this.checkbox);
    },
  },
};
</script>

<style scoped>
.input-checkbox-placeholder {
  text-align: left;
}
.input-checkbox-placeholder:hover {
  text-decoration: none !important;
}
@media only screen and (max-width: 900px) {
  .input-checkbox-placeholder {
    font-size: 0.8rem;
  }
}
@media only screen and (min-width: 901px) {
  .input-checkbox-placeholder {
    font-size: 0.9rem;
  }
}
.squaredThree {
  width: 100% !important;
  height: 25px;
  position: relative;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.squaredThree span {
  margin-left: 1em;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.squaredThree span:hover,
.squaredThree span:focus {
  text-decoration: underline;
}
.squaredThree label {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  background: #dce1e9;
  border-radius: 4px;
  border: 2px solid #c7ccd5;
}
.squaredThree input[type='checkbox'] {
  visibility: hidden;
}
.squaredThree input[type='checkbox']:checked + label:after {
  opacity: 1;
}
</style>
