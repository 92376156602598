import authAxios from '@/utils/authAxios';

export default {
  myData: () => {
    return new Promise((resolve, reject) => {
      authAxios
        .get(process.env.VUE_APP_BACKEND_URL + '/user/my-data')
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  setAccountAsDoctor: ({ id, bank, type, number, phone, rut }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/user/set-account-as-doctor', {
          id,
          bank,
          type,
          number,
          phone,
          rut,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  setAccountAsSuperdoctor: ({ id, bank, type, number, phone, rut }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(
          process.env.VUE_APP_BACKEND_URL + '/user/set-account-as-superdoctor',
          {
            id,
            bank,
            type,
            number,
            phone,
            rut,
          }
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  patientOwnership: id => {
    return new Promise((resolve, reject) => {
      authAxios
        .get(process.env.VUE_APP_BACKEND_URL + `/user/patient-ownership/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /**
   ************************************************************************************************************************
   */
  users: () => {
    return new Promise((resolve, reject) => {
      authAxios
        .get(process.env.VUE_APP_BACKEND_URL + '/user_all')
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  setAdmin: id => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/set_admin', {
          id,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteUser: id => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/delete_user', {
          id,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  removeRole: id => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/remove_role', {
          id,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  setPsychologist: ({ id, bank, type, number, phone, rut }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/set_psychologist', {
          id,
          bank,
          type,
          number,
          phone,
          rut,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  setPatient: ({
    phone,
    rut,
    birthdate,
    region,
    comuna,
    address,
    health,
    id,
  }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/set_patient', {
          phone,
          rut,
          birthdate,
          region,
          comuna,
          address,
          health,
          id,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getPatient: ({ id }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/get_patient', {
          id,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
