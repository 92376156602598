<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="trigger != null"
      class="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-20 sm:align-middle sm:max-w-lg sm:w-full"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="text-center sm:text-left w-full">
                <h3
                  class="text-lg mb-2 leading-6 font-medium text-center w-full text-gray-900"
                  id="modal-title"
                >
                  ¿Estás seguro?
                </h3>
                <!-- <div class="mt-2">
                  <p class="text-sm text-gray-500 mb-2">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Porro atque, voluptas, labore optio ratione fugiat amet,
                    architecto dolores praesentium nesciunt animi et. Nesciunt
                    nisi eos similique vel veniam delectus non!
                  </p>
                </div> -->
                <div class="w-full flex justify-center item-center">
                  <div
                    class="bg-red-200 border border-red-300 text-red-700 h-20 w-20 rounded-full flex justify-center items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-10 w-10"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse items-center"
          >
            <button
              type="button"
              @click="deleteBlock()"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            >
              Ok
            </button>
            <button
              type="button"
              @click="toggle()"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import moment from 'moment';
import BlockService from '@/services/blockService';

function initialState() {
  return {};
}

export default {
  name: 'DeleteBlockAll',
  props: {
    trigger: {
      type: [Object, Array],
      default: null,
      required: false,
    },
  },
  data() {
    return initialState();
  },
  created() {},
  methods: {
    deleteBlock() {
      if (this.trigger != null && this.trigger.length != 0) {
        BlockService.deleteMultiple(this.trigger.map(e => e.id))
          .then(() => {
            this.$emit('toggle', 'save');
          })
          .catch(() => {
            this.error = 'Error de servidor!';
          });
      }
    },
    toggle() {
      this.$emit('toggle', 'ok');
      Object.assign(this.$data, initialState());
    },
  },
  watch: {
    rate() {
      if (this.rate < 0) {
        this.rate = 0;
      }
    },
  },
  computed: {
    block_value() {
      let chileanPeso = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });
      let value = 0;
      if (this.rate) {
        let duration = moment.duration(
          this.end_date_moment.diff(this.start_date_moment)
        );
        return chileanPeso
          .format(duration.asHours() * this.rate)
          .replace(/,/g, '.');
      }
      return chileanPeso.format(value).replace(/,/g, '.');
    },
    start_date_moment() {
      return moment(this.start_date + ' ' + this.start_time, 'YYYY-MM-DD H:mm');
    },
    end_date_moment() {
      return moment(this.start_date + ' ' + this.end_time, 'YYYY-MM-DD H:mm');
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
