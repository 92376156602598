<template>
  <modal-container v-if="trigger != null">
    <div
      v-click-outside="pressOutside"
      class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-20 sm:align-middle sm:max-w-4xl sm:w-full"
    >
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div class="text-center sm:text-left w-full">
            <h3
              class="text-lg mb-2 leading-6 font-medium text-center w-full text-gray-900"
              id="modal-title"
            >
              Editar Reserva
            </h3>
            <div
              v-if="error"
              class="w-full bg-red-200 border rounded-md border-red-300 px-4 py-2 text-red-900 mt-1 mb-2 text-sm"
            >
              {{ error }}
            </div>
            <!-- <div class="mt-2">
              <p class="text-sm text-gray-500 mb-8">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro
                atque, voluptas, labore optio ratione fugiat amet, architecto
                dolores praesentium nesciunt animi et. Nesciunt nisi eos
                similique vel veniam delectus non!
              </p>
            </div> -->
            <div class="w-full grid grid-cols-2 gap-4">
              <div>
                <calendar-component
                  :day="day"
                  @updateDay="d => (day = d)"
                  :month="month"
                  @updateMonth="m => (month = m)"
                  :year="year"
                  @updateYear="y => (year = y)"
                  :daysAvailable="daysGray"
                />
              </div>
              <div class="flex flex-col justify-start items-center">
                <div
                  v-for="(block, blockIndex) in blocks"
                  :key="blockIndex"
                  class="w-full border border-gray-200 overflow-hidden rounded-md bg-white mb-3 grid grid-cols-12"
                >
                  <div
                    :class="
                      selected === block.id ? 'bg-green-600' : 'bg-gray-200'
                    "
                    class="p-2 col-span-2 border-r-4 border-dashed border-gray-300 flex flex-col justify-center items-center"
                  >
                    <span
                      class="text-sm font-semibold mb-2 bg-green-500 px-2 py-1 rounded-md text-white"
                      >{{ formatToHour(block.start_date) }}</span
                    >
                    <span
                      v-if="block.rate || block.full_rate"
                      :class="
                        selected === block.id ? 'text-gray-50' : 'text-gray-900'
                      "
                      class="text-xs font-semibold"
                      >{{
                        chileanPesos(blockCalculationPrice(block, block.rate))
                      }}</span
                    >
                    <span
                      v-if="
                        !block.rate &&
                          !block.full_rate &&
                          block.user &&
                          block.user.doctor
                      "
                      :class="
                        selected === block.id ? 'text-gray-50' : 'text-gray-900'
                      "
                      class="text-xs font-semibold"
                      >{{
                        chileanPesos(
                          blockCalculationPrice(block, block.user.doctor.rate)
                        )
                      }}</span
                    >
                    <span
                      v-if="
                        !block.rate &&
                          !block.full_rate &&
                          block.user &&
                          block.user.psychologist
                      "
                      :class="
                        selected === block.id ? 'text-gray-50' : 'text-gray-900'
                      "
                      class="text-xs font-semibold"
                      >{{
                        chileanPesos(
                          blockCalculationPrice(
                            block,
                            block.user.psychologist.rate
                          )
                        )
                      }}</span
                    >
                    <span
                      v-if="
                        !block.rate &&
                          !block.full_rate &&
                          block.user &&
                          block.user.superdoctor
                      "
                      :class="
                        selected === block.id ? 'text-gray-50' : 'text-gray-900'
                      "
                      class="text-xs font-semibold"
                      >{{
                        chileanPesos(
                          blockCalculationPrice(
                            block,
                            block.user.superdoctor.rate
                          )
                        )
                      }}</span
                    >
                  </div>
                  <div
                    class="col-span-7 px-3 py-2 flex flex-col justify-start items-start"
                  >
                    <span class="text-md text-gray-500 text-left font-semibold"
                      >Disponible</span
                    >
                    <span class="text-md text-gray-900 text-left font-bold">{{
                      block.text
                    }}</span>
                  </div>
                  <div
                    class="col-span-3 border-l border-dashed border-gray-200 px-2 py-2 flex flex-col justify-start items-center"
                  >
                    <button
                      type="button"
                      @click="
                        () => {
                          selected = block.id;
                        }
                      "
                      class="mb-2 bg-gray-200 w-full flex flex-row-reverse justify-between items-center hover:bg-gray-300 text-gray-700 font-bold rounded-md text-sm px-2 py-1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="h-5 w-5"
                      >
                        <path
                          d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span>seleccionar</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse items-center"
      >
        <button
          type="button"
          @click="save()"
          :class="
            selected === null
              ? 'bg-gray-200 cursor-not-allowed'
              : 'bg-green-600 hover:bg-green-700'
          "
          :disabled="selected === null"
          class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
        >
          Guardar
        </button>
        <button
          type="button"
          @click="toggle()"
          class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Cancelar
        </button>
      </div>
    </div>
  </modal-container>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import Calendar from '@/components/app/Calendar';
import ModalContainer from '@/components/modals/ModalContainer';
import BlockService from '@/services/blockService';
import DoctorService from '@/services/doctorService';
import moment from 'moment';
import { mapGetters } from 'vuex';

function initialState() {
  return {
    month: moment().month(),
    year: moment().year(),
    day: moment().date(),
    firstPress: true,
    blocks: [],
    daysGray: [],
    error: '',
    selected: null,
  };
}

export default {
  name: 'EditBlock',
  components: {
    'calendar-component': Calendar,
    'modal-container': ModalContainer,
  },
  props: {
    trigger: {
      type: String,
      default: null,
      required: false,
    },
    id: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return initialState();
  },
  created() {
    moment.locale('es');
    this.getBlocks();
  },
  methods: {
    blockCalculationPrice(block, rate) {
      let value = 0;
      if (block.full_rate) {
        return Math.floor(block.full_rate);
      } else if (rate) {
        let duration = moment.duration(
          moment(
            block.finish_date + ' ' + block.finish_date,
            'YYYY-MM-DD H:mm'
          ).diff(
            moment(block.start_date + ' ' + block.start_time, 'YYYY-MM-DD H:mm')
          )
        );
        return Math.floor(duration.asHours() * rate);
      }
      return value;
    },
    chileanPesos(value) {
      let chileanPeso = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });
      return chileanPeso.format(value).replace(/,/g, '.');
    },
    formatToHour(date) {
      return moment(date, 'YYYY-MM-DD H:mm').format('HH:mm');
    },
    pressOutside() {
      if (!this.firstPress) {
        this.toggle();
      }
      if (this.firstPress) {
        this.firstPress = false;
      }
    },
    getDaysGray() {
      DoctorService.getCalendarGrayDays({
        doctor: this.blocks[0].user.id,
        month: this.month + 1,
        year: this.year,
      })
        .then(response => {
          this.daysGray = response.data;
        })
        .catch(() => {
          console.log('error getting days data');
        });
    },
    getBlocks() {
      this.blocks = [];
      let date =
        (this.day <= 9 ? '0' : '') +
        this.day +
        '/' +
        (this.month + 1 <= 9 ? '0' : '') +
        (this.month + 1) +
        '/' +
        this.year;
      if (this.user.roles.find(e => e.name === 'admin')) {
        BlockService.getByDateAdmin(this.id, date)
          .then(response => {
            this.blocks = response.data.blocks.filter(
              e =>
                !e.is_blocked &&
                !e.is_finish &&
                !e.is_not_available &&
                !e.reservation
            );
            if (this.blocks.length !== 0) {
              this.getDaysGray();
            }
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        BlockService.getByDate(date)
          .then(response => {
            this.blocks = response.data.blocks.filter(
              e =>
                !e.is_blocked &&
                !e.is_finish &&
                !e.is_not_available &&
                !e.reservation
            );
            if (this.blocks.length !== 0) {
              this.getDaysGray();
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    save() {
      if (this.selected) {
        BlockService.movePatientFromBlock({
          from: this.trigger,
          to: this.selected,
        })
          .then(() => {
            this.$emit('toggle', 'save');
            Object.assign(this.$data, initialState());
          })
          .catch(() => {
            this.error = 'Error de servidor!';
          });
      }
    },
    toggle() {
      this.$emit('toggle', 'ok');
      Object.assign(this.$data, initialState());
    },
  },
  watch: {
    day() {
      this.getBlocks();
    },
    month() {
      this.selected = null;
      this.getBlocks();
    },
    year() {
      this.selected = null;
      this.getBlocks();
    },
  },
  computed: {
    ...mapGetters({
      user: ['authentication/user'],
    }),
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
